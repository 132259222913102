var EnglishTraslations = {

    'Más': 'More',
    'Empezar': 'Start',
    'Plantillas': 'Templates',
    'Documentos': 'Documents',
    'Email Certificado': 'Certified Email',
    'Firma Presencial': 'In-Person Signing',
    'Autofirma': 'Self-Signature',
    'Notificaciones': 'Notifications',
    'Tipo de Cuenta': 'Account Type',
    'Administrador': 'Administrator',
    'Usuario': 'User',
    'Plan Actual': 'Current Plan',
    'Bono Activo': 'Active Bonus',
    'No tienes ninguna suscripción activa': 'You have no active subscription',
    'Modificar firma': 'Modify Signature',
    'Mi Cuenta': 'My Account',
    'Calendario': 'Calendar',
    'Soporte': 'Support',
    'Cerrar Sesión': 'Log Out',
    'Volver': 'Back',
    'Personaliza tu plan de subscripción mensual': 'Customize your monthly subscription plan',
    'mes': 'month',
    'o': 'or',
    'por año': 'per year',
    'Comprar': 'Purchase',
    'Usuarios': 'Users',
    'Firmas': 'Signatures',
    'Recordatorios y Notificaciones': 'Send Reminders',
    'Marca Personalizada': 'Custom Brand',
    'Envío Programado': 'Scheduled Sending',
    'Envío por Email': 'Sending by Email',
    'API': 'API',
    'Más info': 'More info',
    '¿Necesitas más? Añade uno de nuestros bonos a tu plan': 'Need more? Add one of our bonuses to your plan',
    'Pagar Ahora': 'Pay now',
    'Realizar Pago': 'Make Payment',
    'Infromación del Plan': 'Plan information',
    'Producto': 'Product',
    'Plan': 'Plan',
    'Detalles': 'Details',
    'Precio': 'Price',
    'Tarifa Regular': 'Regular fee',
    'Notas': 'Note',
    'Se te cargará': 'You will be charged ',
    'por el resto del período de facturación actual': 'for the rest of the current billing period',
    'y la tarifa estádar': 'and the stardard rate',
    'en futuros períodos': 'in future billing periods',
    'Tu plan actual continuará activo por el resto del período de facturación actual. Se te cargará': 'Your current plan will remain active for the remainder of the current billing period. You will be charged',
    'en el próximo período de facturación': 'in the next billing period',
    'y por el resto de períodos.': 'and for future periods.',
    'Nota': 'Note',
    'El coste del bono se cargará en tu cuenta': 'The cost of the bonus will be charged to your account',
    'y se añadirá a tu cuota en los siguientes períodos de facturación': 'and will be added to your quota in the following billing periods',
    'Total a Pagar': 'Total due',
    'Selecciona tu tarjeta': 'Select your card',
    'Añadir nueva tarjeta': 'Add a new card',
    '¡Se han acabado las firmas!': 'You’re out of signatures!',
    '¡No tienes ningún plan activo!': 'You do not have an active subscription!',
    'Puedes renovar o mejorar tu plan para continuar disfrutando de Fingerink': 'You can renew or upgrade your plan to continue enjoying Fingerink',
    'Compra un plan ahora y sigue disfrutando de Fingerink': 'Buy a plan now and continue to enjoy Fingerink',
    'Actual': 'Current',
    '¡Email Confirmado!': 'Confirmed Email!',
    'Haciendo clic en Guardar, aceptas y entiendes que esta la representación legal de tu firma.': 'By clicking Save, you accept and understand that this is the legal representation of your signature.',
    'Guardar': 'Save',
    'Cancelar ': 'Cancel ',
    'Limpiar': 'Clear',
    'Cancelar': 'Cancel',
    'Selecciona tu sello': 'Select your stamp',
    'Circular': 'Circular',
    'Cuadrado': 'Square',
    'Facturación': 'Billing',
    'Próxima Factura': 'Next invoice',
    'No hay próximos pedidos': 'There are no other orders',
    'Servicios Restantes': 'Remaining Services',
    'Mejorar Plan': 'Upgrade your Plan',
    'Seleccionar documento': 'Select a document',
    'Crear documento': 'Create document',
    'Usar plantilla': 'Select template',
    'Subiendo documento': 'Uploading Document',
    'Editor documentos': 'Advanced Document Editor',
    'No hay plantillas': 'No',
    'creadas': 'templates created',
    'Seleccionar': 'Select',
    'Yo':'Me',
    'Continuar': 'Continue',
    'Firmantes': 'Signatories',
    'Campos del documento': 'Document Fields',
    'Información del Documento': 'Document Information',
    'El documento': 'The document',
    'Creado el': 'created on',
    'Tipo de Firma': 'Signature type',
    'Estado': 'State',
    'Ver': 'View',
    'Original': 'Original',
    'Firmado': 'Signed',
    'Tú': 'You',
    'Evidencias': 'Evidences',
    'Asunto': 'Subject:',
    'Firmante': 'Signatory',
    'Tipo': 'Type',
    'Todos': 'All',
    'Avanzada': 'Advanced',
    'Presencial': 'In-Person',
    'Rápida': 'Fast',
    'Completado': 'Completed',
    'Pendiente': 'Pending',
    'Esperando': 'Waiting',
    'Rechazado': 'Declined',
    'Desde la fecha': 'From',
    'Hasta la fecha': 'To',
    'Enviar documento a firmar': 'Send document to sign',
    'Filtro': 'Filter',
    'Nombre': 'Name',
    'Receptor': 'Recipient',
    'Fecha': 'Date',
    'Anterior': 'Previous',
    'Siguiente': 'Next',
    'No se han encontrado resultados con la búsqueda actual.': 'There were no results for your search',
    'No se han creado firmas todavía': 'No signatures have been created yet',
    'Fingerink': 'Fingerink',
    'Hola! Firma Avanzada': 'Hi! Advanced Signature',
    'texto_Avanzada_firmar': 'texto_Avanzada_firmar',
    'Ver documento': 'View document',
    'DESCARGAR': 'Download',
    'Hola! CORREO CERTIFICADO': 'Hi! Certified Email',
    'Texto_burofax firmar': 'Texto_burofax firmar',
    'Ir a': 'Go to',
    'Solución de Firma Electrónica': 'Electronic Signature Solution',
    'Iniciar Sesión': 'Log In',
    'Email': 'Email',
    'Contraseña': 'Password',
    'Recuérdame': 'Remember Me',
    '¿Has olvidado tu contraseña?': 'Have you forgotten your password?',
    'Cargando': 'Loading',
    '¿No tienes cuenta?': 'Not have an account?',
    'Regístrate ahora': 'Sign In Now',
    'Introduce tu email para recuperar la contraseña': 'Enter your email to recover the password',
    'Recuperar Contraseña': 'Recover password',
    '¿Ya recuerdas tu contraseña?': 'Do you remember your password?',
    'Volver al Inicio de Sesión': 'Return to Login',
    'Mis Notificaciones': 'My Notifications',
    'Calendario con las acciones realizadas en la plataforma': 'Calendar with actions performed on the platform',
    'Detalles del Día': 'Day Details',
    'Contenido del Día': 'Activities of the day',
    'Todavía no hay notificaciones': 'There are still no notifications',
    'Recuperar la contraseña': 'Recover Password',
    'Cambiar Contraseña': 'Modify Password',
    'Regístrate Ahora': 'Register now',
    'Nueva Plantilla': 'New Template',
    'No hay plantillas creadas todavía.': 'There is no templates created yet',
    'Editor de texto': 'Text Editor',
    'Añadir Tarjeta': 'Add card',
    'Opciones': 'Options',
    'Eliminar': 'Delete',
    'Actualizar Datos': 'Update Data',
    'Nombre de Empresa': 'Company Name',
    'Web de la Empresa': 'Company Web Page',
    'NIF / CIF': 'NIF / CIF',
    'Dirección': 'Address',
    'Provincia': 'Province / State',
    'CP': 'Postal Code',
    'Ciudad': 'City',
    'País': 'Country',
    'Logo corporativo de tu empresa': 'Corporate logotype of your company',
    'Subir Logo': 'Upload Logotype',
    'Configuración': 'Settings',
    'Mi Equipo': 'My Work Team',
    'Añadir Subcuenta': 'Add Subaccount',
    'Aquí puedes gestionar las subcuentas de tu empresa': 'Here you can manage the subaccounts of your company',
    'Acción': 'Action',
    'ID': 'ID',
    'Expiración': 'Expiration',
    'Pagado': 'Paid Out',
    'Mis Servicios': 'My Services',
    'Comprar bonos y planes': 'Buy bonuses and plans',
    'Aquí aparecen los servicios contratados a Fingerink': 'You will see your subscribed services here',
    'gastadas': 'Spent',
    'Bonos contratados': 'Contracted Bonuses',
    'Programar envíos': 'Schedule message sending',
    'Importar Archivos': 'File Import',
    'Exportar Archivos': 'File Export',
    'Envío por Lotes': 'Batch File Shipping',
    'Próximos pedidos': 'Next orders',
    '¡No hay próximos pedidos!': 'No next orders',
    'Pedido': 'Order',
    'Cambiar Firma': 'Change Signature',
    'Sello': 'Stamp',
    'Cambiar Sello': 'Change Stamps',
    'Subcuentas': 'Subaccounts',
    'Añadir': 'Add',
    'tarjeta': 'card',
    'Comprueba que la información sea correcta y rellena la forma de pago': 'Check that the information is correct and add a payment method',
    'Subscripción': 'Subscription',
    'Mensual': 'Monthly',
    'Anual': 'Yearly',
    'Precio total': 'Total Due',
    'Precio por mes': 'Price per month',
    'Al introducir la tarjeta no se cobrará nada en este momento.': 'When you enter your card details, you will not be charged straight away',
    'Volver al paso anterior': 'Return to the previous step',
    'Primero, rellena la información del usuario': 'First fill in the user information',
    'First Name': 'First Name',
    'Last Name': 'Last Name',
    'Company Name': 'Company Name',
    'Nif/Cif': 'Nif/Cif',
    'Nif/Cif/Iva': 'Nif/Cif/Iva',
    'Password': 'Password',
    'Repeat password': 'Repeat password',
    'Haciendo clic en el botón de arriba aceptas los': 'By clicking on the button above you accept the',
    'Términos y Condiciones': 'Terms and Conditions',
    'y la': 'and the',
    'Política de Privacidad': 'Privacy Policy',
    '¿Ya tienes cuenta?': 'Already have an account?',
    'Registro Fingerink': 'Fingerink Sign Up',
    'A': 'to',
    'Firmas Enviadas': 'Signatures Sent',
    'Documentos Firmados ': 'Signed Documents',
    'Firmas Pendientes': 'Pending Signatures',
    'Firmas Rechazadas': 'Declined Signatures',
    'Estado Actual': 'Current State',
    'Firmas por el tiempo': 'Signatures for time',
    'Completadas': 'Completed',
    'Pendientes': 'Pending',
    'Rechazadas': 'Declined',
    'Expiradas': 'Expired',
    'Firmas completadas': 'Completed Singnatures',
    'Información de las Firmas': 'Signature Information',
    'Fill in signatories data': 'Fill in signatories data',
    'Un firmante por documento': 'One per document',
    'Múltiples firmantes por documento': 'Several per document',
    'Rol': 'Role',
    'Contactos': 'Contacts',
    'Añadir Firmante': 'Add Signatory',
    'Opciones de Envío': 'Shipping options',
    'Mensaje para los Firmantes': 'Message for Signatories:',
    'Enviar una copia cuando se complete': 'Send a copy when completed:',
    'Enviar el documento a los firmantes el día': 'Send the document to the signatories on the day:',
    'Fecha límite para firmar': 'Deadline to sign:',
    'Añadir campos de': 'Add fields for',
    'Mantén presionado para añadir un campo': 'Press and hold to add a field',
    'Si es necesario, añade texto al documento para completarlo': 'If necessary, add text to the document to complete it',
    'Añade los campos que los firmantes deben rellenar': 'Add the fields that signers must fill in',
    'Firmar y completar el documento': 'Sign and complete the document',
    'Edición del Documento': 'Edit the Document',
    'Campos de los Firmantes': 'Signatories Fields',
    'Añade la información que necesites': 'Add the information you need',
    'Mi información': 'My Information',
    'Requerir información al firmante': 'Request information from the signer',
    'Turno de': '',
    'para rellenar los campos': '’s turn to fill in the fields',
    'Este documento ha sido visto': 'This document has been viewed',
    'Pulsa el botón "Firmar" para completar el proceso': 'Click on the "Sign" button to complete the process',
    'Campos a rellenar': 'Fields to be filled out',
    'Estos son los campos que debes rellenar. Haz clic en ellos para verlos': 'These are the fields that you must fill out. Click on them to view them',
    'Subir Documento': 'Upload document',
    'Select document': 'Select document',
    'SIGN TYPE': 'SIGN TYPE',
    'Selecciona el tipo de firma que quieres enviar': 'Select the type of signature you want to send',
    'Enviar': 'Send',
    'Nueva Incidencia': 'New support ticket',
    'Selecciona un departamento, proporciona un asunto al incidente y detalla lo ocurrido': 'Select a section, add a subject and explain what happened',
    'Selecciona el departamento encargado': 'Select the section in charge',
    'Mis Incidencias': 'My Support Ticket',
    'Lista con tus incidencias y sus estados': 'Your support ticket list and their states',
    'Acciones': 'Actions',
    'Ver Incidencia': 'View support ticket',
    'No se han enviado incidencias todavía': 'There are no support tickets yet',









    '¿Comprar?': 'Buy?',
    'Se va a proceder con el pago y finalizar el proceso de compra': 'You will proceed with the payment and finalize the purchase process',
    '¡Correcto!': 'Correct!',
    'Se ha comprado el producto correctamente': 'The product has been purchased correctly',
    'Algo ha ido mal': 'Something went wrong',
    'Ha habido un error en el proceso de compra': 'There was an error in the purchase process',
    'Email confirmado': 'Confirmed Email',
    'No se ha podido confirmar el email': 'The email could not be confirmed',
    'Firma cambiada': 'Signature Changed',
    'Se ha modificado su firma correctamente': 'Your signature has been modified correctly',
    'Sello cambiado': 'Stamp changed',
    'Se ha modificado su sello correctamente': 'Your stamp has been modified correctly',
    'Ha habido un error intentando subir el documento, por favor, inténtelo de nuevo más adelante': 'There was an error trying to upload the document, please try again later',
    'Arrastra tu documento aquí para empezar el proceso de firma': 'Drag your document here to start the signature process',
    'DROPS OR CLICK HERE TO REPLACE DOCUMENT': 'DROPS OR CLICK HERE TO REPLACE DOCUMENT',
    'REMOVE': 'REMOVE',
    'Ha ocurrido algún error. Inténtelo de nuevo.': 'Some error has occurred. Please try again.',
    'Rechazar': 'Reject',
    'Una vez rechazado, no se podrá volver a firmar ni editar.': 'Once rejected, you can not re-sign or edit.',
    'Documento rechazado': 'Rejected Document',
    'Se ha rechazado el documento correctamente': 'The document was rejected correctly',
    'Ha habido un error rechazando el documento': 'There was an error rejecting the document',
    'Esta firma ya no está disponible': 'This signature is no longer available',
    'Faltan campos por rellenar': 'Some fields still need to be filled out',
    'Rellena todos los campos para poder terminar el proceso de firma': 'Fill out all the fields to finish the signature process',
    'Firmar documento': 'Sign document',
    'Una vez firmado, no se podrá volver a firmar ni editar.': 'Once signed, you can not re-sign or edit',
    'Documento Firmado': 'Signed document',
    'Has firmado el documento correctamente': 'You have signed the document correctly',
    'Error firmando el documento': 'Error signing the document',
    'No se ha podido firmar el documento': 'The document could not be signed',
    'Confirma tu correo': 'Confirm your email',
    'Este correo no está confirmado, por favor, revisa tu bandeja de entrada y confirma tu dirección de correo': 'This email is not confirmed, please check your inbox and confirm your email address',
    '¡Usuario o contraseña incorrectos!': 'Incorrect username or password!',
    '¡Correo enviado!': 'Email sent!',
    'Te hemos mandado los pasos a seguir para cambiar la contraseña en un correo electrónico.': 'We have sent you the steps to follow to change the password in an email.',
    'No hemos encontrado ningún usuario registrado con este correo electrónico.': 'We have not found any registered users with this email.',
    'La contraseña se ha cambiado correctamente.': 'The password has been successfully changed.',
    'Hay un error con esta solicitud, por favor, dirígete al login e intenta restablecer la contraseña de nuevo.': 'There is an error with this request, please go to the login and try to reset the password again.',
    'Las contraseñas no coinciden': 'Passwords do not match',
    'Eliminar documento': 'Delete Document',
    'El documento se enviará a la papelera. ¿Estás seguro de que quieres realizar esta acción?': 'The document will be sent to the wastebasket. Are you sure you want to take this action?',
    'Se ha eliminado el documento correctamente': 'The document has been removed correctly',
    'Algo ha ido mal eliminando el documento': 'Something has gone wrong by deleting the document',
    'El documento "': 'The document "',
    ' se enviará a la papelera. ¿Estás seguro de que quieres realizar esta acción?': '" will be sent to the wastebasket. Are you sure you want to take this action?',
    'No tienes plantillas disponibles': 'You do not have templates available',
    'No te quedan plantillas, por favor contrata un plan superior o un bono con plantillas': 'You do not have templates left, please upgrade your plan or a bonus with templates',
    'Ir a plantillas': 'Go to templates',
    'Guardar plantilla': 'Save template',
    'Se ha guardado la plantilla correctamente': 'The template has been saved correctly',
    'Ha habido un error intentando crear la plantilla, por favor, inténtelo de nuevo más adelante': 'There was an error trying to create the template, please try again later',
    '¿Estás seguro que quieres salir del proceso de firma? Se pueden perder datos': 'Are you sure you want to get out of the signing process? You may lose data',
    'Tarjeta añadida': 'The card has been added correctly',
    'Error añadiendo tarjeta': 'Error adding card',
    'Esta tarjeta no se ha podido añadir. Por favor, inténtelo de nuevo más adelante': 'This card could not be added. Please try again later',
    '¿Estás seguro?': 'Are you sure?',
    'Al guardar la empresa los datos anteriores se perderán': 'When saving the company the previous data will be lost',
    'Se han editado los datos de usuario para que los cambios sean visibles, por favor, recarga la página.': 'User data has been edited so that changes are visible, please reload the page.',
    'Ha habido un error actualizando los datos de la empresa': "There was an error updating the company's data",
    'Al guardar el usuario los datos anteriores se perderán': 'When saving the user the previous data will be lost',
    'Se han editado los datos del usuario': 'User data has been edited',
    'Ha habido un error actualizando los datos del usuario': "There was an error updating the user's data",
    'Al deshabilitar el usuario no podrá tener acceso a la aplicación': 'By disabling the user they will not be able to access the application',
    'Se ha deshabilitado al usuario': 'The user has been disabled',
    'Ha habido un error deshabilitando al usuario': 'There was an error disabling the user',
    'No te quedan usuarios': 'You do not have users left',
    'No puedes crear más usuarios, cambia tu plan o añade un bono de usuarios': 'You cannot create more users, change your plan or add a user bonus',
    'Vas a crear un nuevo usuario que podrá acceder a la aplicación y enviar documentos para firmar': 'You will create a new user that will be able to access the application and send documents to sign',
    'Se ha creado el nuevo usuario': 'The new user has been created',
    'Ha habido un error creando al usuario': 'There was an error creating the user',
    'Al habilitar el usuario podrá tener acceso a la aplicación': 'By enabling users they will be able to access the application',
    'Se ha habilitado al usuario': 'The user has been enabled',
    'Ha habido un error habilitando al usuario': 'There was an error enabling the user',
    '¿Cancelar pedido?': 'Cancel order?',
    'Se va a cancelar el pedido': 'The order will be canceled',
    'Se ha cancelado correctamente': 'The order has been canceled successfully',
    'Ha habido un error cancelando el pedido': 'There was an error canceling the order',
    'Vas a cambiar tu contraseña de acceso a la aplicación, la anterior ya no funcionará.': 'You are going to change your password to access the application, the previous one will no longer be valid.',
    '¡Se ha cambiado la contraseña!': 'Password has been changed!',
    'Ha habido un error actualizando la contraseña': 'There was an error updating the password',
    'La repetición de la contraseña no coincide con la nueva contraseña': 'The repetition of the password does not match the new password',
    'Es necesario rellenar el email y el nombre como mínimo': 'Please fill in the email and name at least',
    'Se ha cambiado la tarjeta por defecto a esta': 'Now, this is your default card',
    'No se ha podido cambiar la tarjeta por defecto': 'Failed to change the default card',
    '¿Eliminar?': 'Delete?',
    'Se va a proceder a eliminar la tarjeta': 'This will remove the card',
    'Tarjeta eliminada': 'Deleted card',
    'Se ha eliminado la tarjeta correctamente': 'The card has been removed correctly',
    'No se ha podido eliminar la tarjeta': 'The card could not be removed',
    'Se ha creado el usuario, por favor, revisa tu bandeja de entrada para confirmar el correo electrónico y poder acceder.': 'The user has been created, please check your inbox to confirm the email and be able to access.',
    'No se ha podido realizar el registro correctamente': 'The registration could not be completed correctly',
    'Cada firmante firmará su documento de forma independiente': 'Each signatory will sign their document independently',
    'Todos los firmantes firmarán en el mismo documento': 'All signatories will sign in the same document',
    'Paso 1': 'Step 1',
    'Si es necesario, añade texto al documento': 'If necessary, add text to the document',
    'Cerrar': 'Close',
    'Paso': 'Step',
    'Arrastra los campos que deba rellenar': 'Drag the fields that must be completed by',
    'Step ': 'Step ',
    'Texto': 'Text',
    'Firma': 'Signature',
    'Draw signature': 'Draw signature',
    'Mi nombre': 'My name',
    'Mis apellidos': 'My surnames',
    'Mi email': 'My email',
    'Mi empresa': 'My company',
    'Dirección de empresa': 'Company address',
    'Mi teléfono': 'My phone',
    'Mi ID / Tax Id / VAT number': 'My ID / Tax Id / VAT number',
    'Adjuntar archivo': 'Attach file',
    'Área de texto': 'Text Area',
    'Apellidos': 'Surnames',
    'Empresa': 'Company',
    'Teléfono': 'Phone Number',
    'Cantidad': 'Quantity',
    'ID / Tax Id / VAT número': 'ID / Tax Id / VAT number',
    'Logo': 'Logo',
    'Mi firma': 'My signature',
    'Hay campos sin rellenar': 'There are unfilled fields',
    'Por favor, rellena todos los campos para poder completar la firma.': 'Please, fill in all the fields to be able to complete the signature.',
    'Firma en un click': 'Fast Signature',
    'El documento se firma mediante un botón, sin necesidad de nada más': 'The document is signed by a button, without the need for anything else',
    'Firma avanzada': 'Advanced Signature',
    'Firma en persona': 'In-Person Signature',
    'El documento se firma en tu dispositivo': 'The document is signed on your device',
    'No tienes firmas disponibles': 'You do not have available signatures',
    'No te quedan firmas para enviar, por favor contrata un plan superior o un bono con firmas': 'You do not have any signatures to send, please upgrade your plan or purchase a signature bonus',
    'Ir a firmas enviadas': 'Go to Documents',
    '¿Enviar a firmar este documento?': 'Send to sign this document?',
    '¿Terminar el proceso de autofirma?': 'Finish the self-signature process?',
    '¿Finalizar la firma presencial?': 'Finish the signature in person?',
    'Se ha enviado la firma correctamente': 'The signature has been sent correctly',
    'Se ha enviado la firma correctamente, ¿Deseas guardar el documento como plantilla?': 'The signature has been sent correctly, do you want to save the document as a template?',
    'Guardar como plantilla': 'Save as template',
    'Ir a documentos': 'Go to Documents',
    'Guardado': 'Saved',
    'Ha habido un problema guardando el documento como plantilla': 'There was a problem saving the document as a template',
    'La incidencia se ha creado correctamente': 'The support ticket was created correctly',
    'Ha habido un error creando la incidencia': 'There was an error creating the support ticket',
    'Se ha creado la incidencia correctamente': 'The support ticket has been created correctly',
    'Detalla la incidencia': 'Detail the support ticket',
    'El mensaje enviado está vacío': 'The message sent is empty',
    'No se ha podido crear la incidencia correctamente': 'The support ticket could not be created correctly',
    'Se va a subir el archivo': 'The file will be uploaded',
    'Archivo subido': 'File uploaded',
    'El archivo se ha subido correctamente': 'The file has been uploaded correctly',
    'Ha habido un error intentando subir el archivo, por favor, inténtelo de nuevo más tarde': 'There was an error trying to upload the file, please try again later',




    'Enviar a firmar': 'Send to sign',
    'Se solicita a los firmantes trazar la firma en el documento': "It's required draw the signature in the document",




    ////////////////////////////////////////////////////////////////////
    //Nuevas 23 / 5 /18
    //////////////////////////////////////////////////////////////////
    'CIF/IVA': 'CIF/IVA',
    'Estoy dado de alta en el ROI': 'I am registered in the ROI',
    'Repetir contraseña': 'Repeat password',
    'Particular': 'Individual',
    'Precio por año': 'Price per year',
    'IVA': 'VAT',
    'Acepto los': 'I accept the',
    'Documento enviado': 'Document Sent',
    'Documento completado': 'Document Completed',
    'Nuevo usuario': 'New User',
    '¿Reenviar correo?': '¿Resend Email?',
    'Se va a reenviar el correo al firmante': 'The email will be forwarded to the signer', /// -------------
    'El correo se ha enviado satisfactoriamente': 'The email has been sent successfully',
    'Ha habido un error enviando el correo': 'There was an error sending the email',
    'Reenviar correo': 'Resend email',
    'Cambiar de plan': 'Change Plan',
    'Cambio de plan': 'Change of plan',
    'Costes': 'Cost',
    'más IVA': 'plus VAT',
    'Mi Perfil': 'My Profile',
    'Edita tu perfil': 'Edit your profile',
    'Fecha Registro': 'Registration Date',
    'Notificaciones por correo': 'Email Notifications',
    'Habilitado': 'Enabled',
    'Deshabilitado': 'Disabled',
    'Recibir NewsLetter': 'Receive NewsLetter',
    'Productos': 'Products',
    'Total': 'Total',
    'Panel de configuración de tu cuenta en Fingerink': 'Configuration Panel',
    'Mi Empresa': 'My Company',
    'Mis Tarjetas': 'My Cards',
    'Mis Facturas': 'My Invoices',
    'Mi Agenda': 'My Contacts',
    'Cuentas API': 'API Accounts',
    'Aquí aparece toda la información sobre tu empresa': 'Here is all the information about your company',
    'Aquí aparecen las tarjetas que podrás utilizar para pagar tus servicios': 'Your cards',
    'Tarjeta con la que se realizarán los pagos': 'Card with which payments are made',
    'Otras tarjetas': 'Other Cards',
    'Aquí se muestra tu facturación': 'Your billing is shown here',
    'Añadir Cuenta': 'Add Account',
    'Añadir Cuenta API': 'Add API Account',
    'Aquí pudes gestionar las subcuentas de tu empresa': 'Here you can manage the subaccounts of your company',
    'No hay cuentas API creadas': 'No API accounts created',
    'Ver API-KEY': 'View API-KEY',
    'Las contraseñas introducidas no coinciden': 'The entered passwords do not match',
    'La cuenta ha sido creada. Puedes consultar ya tu clave secreta': 'The account has been created. You can already see your secret key',
    'Vas a cambiar tu contraseña de la cuenta de la API, la anterior ya no funcionará.': 'You are going to change your password for the API account, the previous one will no longer work',
    'La contraseña ha sido modificada': 'The password has been modified',
    'Al borrar esta cuenta dejarás de poder usar la api con estos accesos': 'When you delete this account you will no longer be able to use the API with these accesses',
    'La cuenta ha sido eliminada': 'The account has been deleted',
    'Crear Cuenta': 'Create Account',
    'Repite contraseña': 'Repeat password',
    'Contraseña Actual': 'Current Password',
    'Nueva contraseña': 'New Password',
    'Accesos API': 'API Accesses',
    'API-KEY': 'API-KEY',
    'Aquí puedes apuntar tus contactos para recordarlos en tus envíos': 'Here you can add your contacts to remember them in your sendings',
    'No hay contactos': 'No contacts created yet',
    'Gestionar Grupos': 'Manage Groups',
    'Se va a borrar este contacto, esta acción no se puede deshacer': 'This contact will be deleted, this action can not be undone',
    'Se ha eliminado el contacto': 'The contact has been removed',
    'Se ha añadido el usuario a la agenda y al grupo seleccionado': 'The user has been added to your contacts and to the selected group',
    'Se ha añadido el usuario a la agenda': 'The user has been added to your contacts',
    'Grupos': 'Groups',
    'Crear Grupo': 'Create Group',
    'Aquí puedes organizar tus contactos en grupos pudiendo simplificar tus envíos masivos': 'Here you can organize your contacts in groups to simplify your bulk mailings',
    'Editar': 'Edit',
    'No hay grupos creados': 'There are no groups created',
    'Grupo creado correctamente': 'Group created correctly',
    'Se va a borrar este grupo, esta acción no se puede deshacer': 'This group is going to be deleted, this action can not be undone',
    'Se ha eliminado el grupo': 'The group has been removed',
    'Se han editado los usuarios de este grupo': 'The users of this group have been edited',
    'Guardar Cambios': 'Save Changes',
    'Aquí podrás añadir o quitar miembros a un grupo': 'Here you can add or remove members to a group',
    'Editar Grupo': 'Edit Group',
    'Seleccionado': 'Selected',
    'Añadir contacto': 'Add Contact',
    'Url web': 'URL WEB',
    'Dirección (Línea 1)': 'Direction (Line 1)',
    'Dirección (Línea 2)': 'Direction (Line 2)',
    'Grupo agenda': 'Contacts Group',
    'ha solicitado tu firma en el siguiente documento a través de Fingerink': 'has requested your signature in the following document through Fingerink',

    //Nuevas 24/5/18

    'Buscar por asunto': 'Search by subject',
    'Finalizar': 'Finish',
    'Nombre de la plantilla': 'Template Name',
    'Nombre del Grupo': 'Group Name',
    'Campo': 'Field',
    'Asunto del mensaje que se envia a destinatario y copias': 'Email subject for signers',
    'Texto que aparecerá en el correo': 'Email Text',
    'Fecha de envío': 'Send Date',
    'Caducidad': 'Expiration',
    'Motivo de la incidencia': 'Support Ticket Reason',
    '¿Cómo Funciona?': '¿How does it work?',
    'Documento creado': 'Created Document',


    //Nuevas 28/5/18
    '¡Tu empresa no tiene suficientes usuarios contratados!': 'Your company does not have enough contracted users!',
    'Por favor, contacte con el administrador de su empresa para que contrate un plan superior o deshabilite usuarios': 'Please contact your company administrator to hire a superior plan or disable users',



    'Tienes más plantillas de las contratadas': 'You have more templates than those contracted',
    'Para usar las plantillas, borre plantillas o contrata un plan superior': 'To use the templates, delete templates or hire a superior plan',
    'Crear Documento': 'Create document',


    //Nuevas 28/5/18
    "Firma Creada": 'Signature created',
    "Correo enviado": 'Sended email',
    "Email abierto": 'Email opened',
    "Documento abierto": 'Signature opened',
    "Términos y condiciones aceptados": 'Terms and Conditions accepted',
    "Firma vista": 'Signature viewed',
    "Documento firmado": 'Signature signed',


    'Error': 'Error',
    'Borrador': 'Draft',
    'Esperando a enviar': 'Waiting to send',
    'Expirado': 'Expirated',
    'Ver detalles': 'View details',
    'Se ha añadido la tarjeta correctamente': 'The card has been added correctly',

    '¡Usuario deshabilidado!': 'User disabled!',
    'Por favor, contacte con el administrador de su empresa': 'Please contact your company administrator',
    'Aceptar': 'Accept',

    'Total a pagar': 'Total to pay',
    'Ver próximos pedidos': 'See next orders',
    'Elige tu firma': 'Choose',
    'Sube tu firma': 'Upload',
    'Añadir firma': 'Add',
    'Arrastra aquí o haz click para reemplazar': 'Drag here or click to replace',
    'Arrastra aquí el documento o haz click': 'Drag here the document or click here',
    'Firma aquí': 'Sign Here',
    'Estadísticas': 'Statistics',
    'Enviar documento': 'Send Document',
    'Mejora tu plan o añade firmas': 'Upgrade your plan or add signatures',
    'Enviadas': 'Sent',
    'Documentos restantes': 'Remaining Documents',
    'Enviados': 'Emails Sent',
    'Completados': 'Emails Received',
    'Dispositivo': 'Device',
    'Tiempo': 'Time',
    'Tu firma': 'Your Signature',
    'Tu logo': 'Your Logo',
    'Tu sello': 'Your Stamp',
    'Esperando firma': 'Pending',
    'Estadísticas usuarios': 'Users Statistics',
    'Envío': 'Delivery',
    'días': 'days',
    'Mi cuenta': 'My account',
    'Cambiar': 'Change',
    'Proceder al pago': 'Order now',
    'y': 'and',

    'Emails Enviados': 'Emails Sent',
    'Emails Sin Abrir': 'Unopened Emails',
    'Emails Abiertos': 'Opened Emails',

    'Selecciona plantilla': 'Select template',
    'Quiero emails certificados': 'I want certified emails',
    'Actividad': 'Activity',
    'Dibuja tu firma': 'Draw signature',
    'Firmar': 'Sign',
    'Usuarios por documento': 'Users per document',
    'Equipo de trabajo': 'Work Team',
    'Introducir tarjeta más adelante': 'Add card later',
    'Subir documento': 'Upload document',
    'Adjuntar imagen': 'Attach image',
    'Comprar bono': 'Buy bonus',
    'Cancelar plan actual y comprar el nuevo plan': 'Cancel actual plan and pay the new plan right now',
    'Cambiar el plan en el próximo período de facturación': 'Change plan in the next billing period',
    'Documento visto': 'Seen document',
    'Documento': 'Document',
    'Archivo Adjunto': 'Attached File',
    '¡Login incorrecto!': 'Login failed!',
    'Nombre de usuario o contraseña incorrectos': 'Incorrect username or password ',
    'Editar documento': 'Edit document',
    'Cuenta': 'Account',
    'Suscripción': 'Suscription',
    'Gestión de grupos de contactos': 'Contact Groups Management',
    'Atrás': 'Back',
    'Subir logo': 'Upload logo',
    'Seleccionar sello': 'Select stamp',
    'Cambiar contraseña': 'Change password',
    'Sello corporativo de tu empresa': 'Corporate stamp of your company',
    '¿Es Particular?': 'Individual?',
    '¿Pertenece ROI?': 'Does ROI belong?',
    'Descargar': 'Download',
    'Crear Grupo de Agenda': 'Crear Grupo de Agenda',
    'Seleccionar todos': 'Seleccionar todos',
    'Insertar Firmantes': 'Insert Signatories',
    'Hay firmantes sin campo de firma': 'There are signatories without sign field',
    'Inserta al menos un campo de firma a cada firmante para poder continuar': 'Add at least one signature field to each signatory to conitnue',

    'Al eliminar el usuario no podrá tener acceso a la aplicación, los documentos enviados pasarán a pertenecer al administrador': 'When deleting the user will not be able to access the application, the documents   will belong to the administrator',
    'Se ha eliminado al usuario': 'The user has been deleted successfully',
    'Ha habido un error eliminando al usuario': 'An error has occurred deleting the user',
    'Si': 'Yes',
    'No': 'No',
    'Hay firmantes que no tienen campo de firma. ¿Quieres continuar de todas formas?': 'There are signatories without sign field. ¿Do you want to continue anyway?',
    'Editar y enviar': 'Edit and send',
    'Editar y guardar': 'Edit and save',
    'Añadir contactos': 'Add contacts',

    '¡Usuario ya registrado!': 'User already registered!',
    'El email introducido ya tiene una cuenta de Fingerink asociada': 'This email is already registered in Fingerink',
    'Ha habido un error durante el registro': 'An error has occurred during the registration process',
    'Elige un paso': 'Choose a step',

    'Paso 1. Añadir mis datos': 'Step 1. Add my data',
    'Quiero añadir mi firma y mis datos antes de ir al paso 2':'I would like add my signature and my data before the step 2',
    'Paso 2. Solicitar datos al firmante':'Step 2. Request fields to the signatories',
    'Solo quiero añadir los campos que voy a solicitar a mis firmantes':'I just want to add the fields that must be completed by the signatories',

    'Recuerda: aunque introduzcas la tarjeta ahora, tienes 15 días para probar la plataforma y cancelar tu cuenta cuando quieras': 'Remember: even if you enter the card now, you have 15 days to try the platform and cancel your account whenever you want',
    'Email (Se enviarán los datos de acceso a esta dirección)': 'Email (An invitation will be sent to this address)',
    'Añadir categoría': 'Add category',

    'es tu turno de firma': ", it's turn to sign",
    'Por favor, rellena los campos que': 'Please, fill the fields that',
    'te ha solicitado': 'has requested',

    'Acepta los Términos y Condiciones y la Política de Privacidad': 'Accept the Terms and Conditions and the Privacy Policy',
    'Haz click en Ver documento': 'Press the button "View document"',
    'Haz click en Descargar para guardar una copia del documento': 'Click on Download to save a Document copy',
    'Revisa el documento y haz click en Firmar para completar el proceso de firma': 'Review the document and press Finish to end the signature process',
    'Revisa y firma el documento en la pantalla de tu dispositivo': "Review and sign the document on your device's screen",
    'Haz click en Firmar para completar el proceso de firma': 'Click on Sign to end the signature process',
    'Omitir': 'Skip',
    'Puedes empezar enviando un documento a firmar desde aquí': 'You can start by sending a document to sign from here',
    'Desde este apartado o desde el botón azul de la izquierda, envía documentos para que otros los firmen': 'From this section or from the blue "Send to sign" button on the left, send documents for others to sign',
    'Aquí puedes personalizar tu firma. La añadirás a los documentos que quieras firmar': 'Here you can customize your signature. You will add it to the documents you want to sign',
    'Añade el sello de tu empresa o tu sello personal para agregarlo a tus documentos': 'Add your company stamp or your personal stamp to add it to your documents',
    'Sube un logo para personalizar tus documentos con tu marca': 'Upload a logo to personalize your documents with your brand',
    'Email certificado: con esta opción puedes enviar documentos que necesites que el destinatario reciba y vea': 'Certified email: with this option you can send documents that you need the recipient receive and read them',
    'Firma presencial: Utiliza esta opción cuando necesites que tu acompañante firme un documento': 'In person signature: Use this option when you need your companion sign a document',
    'Autofirma: Elige esta opción cuando quieras firmar un documento tu mismo y eviarlo a alguien o descargarlo en tu dispositivo': 'Self-sign: Choose this option when you want to sign a document yourself and send it or download it in your device',

    'Arrastrar archivos o click aquí para subir': 'Drag files or click here to upload',
    'Ver actividad': 'View activity',
    'Quiero recibir Avisos y Ofertas de Fingerink': 'I would like to receive discounts and news by e-mail',
    'Vas a eliminar esta categoría. Las firmas de esta categoría serán movidas a Principal': 'The category will be deleted. The signatures with this category will be moved to Principal',
    'Mover Categoría': 'Move to category',
    'Mover a Principal': 'Move to Principal',


    'al mes': 'per month',

        
    'Por favor, confirma tu email y podrás empezar a firmar documentos':'Please, confirm your email in order to start to sign documents',
    'Tu período de prueba expira en':'Your free trial period expires in',
    'Compra tu plan':'Buy your plan',
    
    
    // FIRMA FINALIZADA
    'Documento Firmado Correctamente':'Document signed successfully',
    'Ya esta! Tu firma ha sido firmada electronicamente utilizando Fingerink':'It is done! Your signature has been signed electronically using Fingerink',
    'Si sientes curiosidad sobre como funciona Fingerink, no dudes en pasarte por nuestra web':'If you are curious about how Fingerink works, do not hesitate to visit our website',
    'Descubir Fingerink':'Discover Fingerink',
    
    'Principal':'Main',
    'Seleccionar perfil':'Select profile',




    'Solicitar DNI':'Require ID identificacion',
    'Comenzó el':'Started on',
    'Termina el':'Finish on',
    'El firmante ha solicitado que verifiques tu identidad con tu DNI': 'It has been requested that you verify your identity with your ID',
    'Haz una foto a la parte delantera de tu DNI':'Take a picture to the front of your ID',
    '¿Es correcta la foto?' :'Is the photo correct?' ,
    'Haz una foto a la parte trasera de tu DNI':'Take a picture to the back of your ID',
    'Verificando identidad':'Verifying identity',
    'Capturar':'Capture',
    'Repetir':'Repeat',
    'Realizar la foto trasera':'Take the back photo',
    'Checkbox':'Checkbox',
    'Radiobutton':'Radiobutton',
    'Mark with a cross':'Mark with a cross',
    'Click en la casilla para marcarla':'Click to marck the field',
    'Click para seleccionar una fecha':'Click to select a date',
    'Click para firmar':'Click to sign',
    'Adjunta un archivo':'Attach a file',

    'No te quedan créditos':'You do not have credits left',
    'Subir archivo':'Upload file',
    'Color':'Color',
    'Actualizar al plan':'Update to plan',
    'IVA no incluido':'VAT not included',
    'Si dispones de un CIF válido dentro de la unión europea no deberás pagar el IVA, excepto en el caso de ser una empresa Española, donde aplicamos el IVA correspondiente':'If you have a valid VAT number within the European Union you will not have to pay the VAT, except in the case of being a Spanish company, where we apply the corresponding VAT',
    'Licencias':'Licenses',
    'Periodos':'Periods',
    'Cambiar plan actual y pagar en este momento':'Change current plan and pay at this moment',
    'Cambiar el plan en el próximo periodo de facturación':'Change the plan in the next billing period',
    'Cambiar plan':'Change plan',
    'Continuar y pagar':'Continue and pay',
    'Al hacer click en continuar aceptas las condiciones legales':'By clicking continue you accept the legal conditions',
    'Primero rellena tus datos de facturación':'First, fill in your billing information',
    'Enviar el documento individualmente a cada destinatario':'Send the document individually to each recipient',
    'Enviar el mismo documento a todos los destinatarios':'Send the same document to all recipients',
    'Selecciona tipo de firma o envío':'Select type of signature or delivery',
    'Correo Certificado':'Certified Mail',
    'DNI firmantes':'ID Signers',
    'Nuevo documento':'New document',
    'Inicio':'Start',
    'Simple':'Simple',
    'Certificado':'Certificate',
    'Organización':'Organization',
    'Método de pago':'Payment method',
    'Mejorar':'Upgrade',
    'Créditos totales':'Total credits',
    'de':'of',
    'consumidos':'used credits',
    'Nuestros planes y precios':'Our plans and prices',
    'Elige el que más se adapte a tus necesidades':'Choose the one that best suits your needs',
    'por licencia':'by license',
    'DAY':'days',
    'MONTH':'per month',
    'Firma electrónica':'Electronic signature',
    'créditos':'credits',
    'Hasta':'Up to',
    'Nueva licencia':'New license',
    'Añadir datos':'Add organization data',
    'Componentes':'Team',
    'de Equipo':'Members',
    'Equipo':'Work Team',
    'Deshabilitar':'Disable',
    'Desde este apartado o desde el botón verde de la izquierda, envía documentos para que otros los firmen':'From this section or from the green button on the left, send documents for others to sign',
    'Empieza un proceso de firma ahora':'Start a signature process now',
    'Envía un documento':'Send a document',
    'Últimos documentos firmados':'Last signed documents',
    'No hay documentos firmados todavía':'No documents signed yet',
    'Draft':'Draft',
    'Advanced':'Advanced',
    'Fast':'Simple',
    'SelfSigning':'Self-Signing',
    'InPerson':'In-Person',
    'Proceso':'Process',
    'PENDING':'Pending',
    'REFUSED':'Refused',
    'WAITING':'Waiting',
    'COMPLETED':'Completed',
    'SEND':'Document Sent',
    'OPENSIGN':'Open email',
    'ACCEPT':'Acceptance of terms and conditions',
    'VIEW':'Document seen',
    'SIGN':'Document Signed',
    'Sin permisos':'No permissions',
    'Algún dato no es correcto':'There is some incorrect information',
    'Ha habido un error al eliminar la categoría':'There was an error removing the category',
    'Cambiar categoría':'Change category',
    'Previsualizar':'Preview',
    'Ir al documento':'Go to document',
    '¿Quieres que te reenviemos el correo de confirmación de email?':'Do you want us to resend your email confirmation email?',
    'Hemos enviado un email a tu dirección de correo electrónico para poder confirmar tu cuenta.':'We have sent an email to your email address to confirm your account.',
    'Por favor, confirma tu email y empieza a firmar documentos':'Please, confirm your email and start signing documents',
    'Compra tu plan ahora':'Buy your plan now',
    'Documento a enviar':'Document to send',
    'Destinatarios y configuración de mensaje':'Recipients and message settings',
    'Enviar el día':'Send document on',
    'Obligatorio':'Required',
    'Fondo blanco':'White background',
    'Mi ID / Tax Id / VAT number")':'My ID / Tax Id / VAT number")',
    'Se ha comprado la licencia correctamente':'The license has been purchased correctly',
    'Ha habido un problema al comprar la licencia':'There was a problem when buying the license',
    'Añade tantas licencias como necesites':'Add as many licenses as you need',
    'Se va a realizar el cobro del siguiente importe':'The next amount will be charged',
    'IVA incluido':'VAT included',
    'Introduce los datos del nuevo usuario':'Enter the new users data',
    'Los usuarios recibirán una invitación de Fingerink a su email':'Users will receive an invitation from Fingerink to their email',
    'En tu factura mensual se reflejarán las licencias añadidas':'On your monthly bill will be reflected the added licenses',
    'sin IVA':'VAT not included',
    'Añadir la información':'Add information',
    'Componentes de equipo':'Team Members',
    'Plantillas consumidas':'Used Templates',
    'Plantillas totales':'Total templates',
    'Rellena la información del documento':'Fill in the document information',
    'Ha habido un problema al verificar tu identidad, por favor inténtelo de nuevo':'There has been a problem verifying your identity, please try again',
    'Tu documento ha sido firmado electronicamente utilizando Fingerink':'Your document has been signed electronically using Fingerink',
    'Si sientes curiosidad sobre como funciona Fingerink, puedes consultar toda la información que necesites en nuestra web':'If you are curious about how Fingerink works, you can consult all the information you need on our website',
    'Debes aceptar la privacidad y los términos y condiciones':'You must accept the privacy and the terms and conditions',
    'Firma Simple':'Simple Signature',
    'Documento a firmar':'Document to sign',
    'Añadir desde contactos':'Add from contact list',
    'Modalidad de firma':'Signature Mode',
    'Requerir DNI':'ID card required',
    'Mensaje':'Message',
    'Cambiar email':'Change email',
    'Enviar recordatorio':'Send reminder',
    'Filtros':'Filters',
    'Categorias':'Categories',
    'Fechas':'Dates',
    'Sin filtro':'No filter',
    'Hoy':'Today',
    'Esta semana':'This week',
    'Este mes':'This month',
    'Elegir':'Choose',
    'Se solicita la rúbrica a tus firmantes':'The signature is requested to your signers',
    'El documento se firma con un botón':'The document is signed with a button. Its like accepting the terms and conditions of a service',
    'Equivale a un envío certificado':'Equivalent to a certified shipment',
    'El documento se firma sobre el propio dispositivo':'The document is signed on the device',
    'Firma el documento tu mismo':'Sign the document yourself',
    'Descripción de campo':'Fild description',
    'Creando plantilla':'Template creation',
    'Nº de licencias contratadas':'Total hired licenses',
    'Licencia':'License',

    'Empieza a enviar y firmar documentos ahora con Fingerink':'Start sending and signing documents now with Fingerink',
    'Sube un documento y selecciona el tipo de firma que quieras realizar':'Upload a document and select the type of signature you want to make',
    'Añade los firmantes del documento y elige la modalidad de firma':'Add the signers of the document and choose the signature mode',
    'Añade al documento los campos que deben rellenar los firmantes':'Add to the document the fields that must be filled in by the signatories',
    'Los firmantes recibirán el documento a su email, donde podrán firmarlo':'The signatories will receive the document in their email and they will be able to sign it',
    'Personaliza tus envíos. Añade el logo de tu empresa si quieres que les aparezca a tus firmantes':'Customize your shipments. Add your companys logo if you want it to be seen by your signatories',
    'Por último, rellena tus datos de facturación y recuerda que no tienes que pagar nada ahora':'Finally, fill in your billing information and remember that you do not have to pay anything now',
    
    
    
    

    '': ''




};
